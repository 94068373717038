import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M8.65 23.162h6.696c5.581 0 7.813-2.232 7.813-7.812V8.654c0-5.581-2.232-7.813-7.813-7.813H8.65C3.07.841.838 3.073.838 8.654v6.696c0 5.58 2.232 7.812 7.812 7.812"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "m7.75 12 2.83 2.83 5.67-5.66"
    }, null, -1)
  ])))
}
export default { render: render }